import * as React from "react";
import {Component, useState} from "react";
import {AppLayout, Box, Container, ContentLayout, Grid} from "@amzn/awsui-components-react";
import "./styles.scss";
import Content, {NotificationPreferenceViewType} from "src/components/notification-preferences/content";
import {NOTIFICATION_PREFERENCE_ERROR_PATH } from "src/components/App";
import {Navigate, useParams} from "react-router-dom";

export const TOKEN_PARAM_KEY = "T";

export enum PreferenceStatus {
    ACCEPT = "ACCEPT",
    REJECT = "REJECT"
}

export function NotificationPreferences() {
    const [viewType, setViewType] = useState(NotificationPreferenceViewType.unsubscribeForm);
    const [error, setError] = useState(false);
    let { token } = useParams();

    return (
        <ContentLayout header={null} disableOverlap={true}>
            <Grid
                disableGutters
                gridDefinition={[
                    {
                        colspan: {xl: 4, l: 4, m: 6, s: 8, xxs: 12},
                        offset: {xl: 4, l: 4, m: 3, s: 2, xxs: 0}
                    },
                ]}>
                <Content viewType={viewType} />
            </Grid>
            {error && <Navigate to={NOTIFICATION_PREFERENCE_ERROR_PATH} replace={true} /> }
        </ContentLayout>
    );;
}