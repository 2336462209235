import * as React from "react";
import {Component} from "react";
import {Box, Grid} from "@amzn/awsui-components-react";
import Content, {NotificationPreferenceViewType} from "src/components/notification-preferences/content";

export default class NotificationPreferencesError extends Component {


    render() {

        return (
            <Box padding={{top: 'xxxl'}}>
                <Grid
                    disableGutters
                    gridDefinition={[
                        {
                            colspan: {xl: 4, l: 4, m: 6, s: 8, xxs: 12},
                            offset: {xl: 4, l: 4, m: 3, s: 2, xxs: 0}
                        },
                    ]}>
                    <Content viewType={NotificationPreferenceViewType.error} />
                </Grid>
            </Box>
        );;
    }
}