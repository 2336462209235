import {
    LocalizationContextBuilder,
    MessageBundle,
    LocalizationContext,
} from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from './constants';

const LOCALIZATION_CONTEXTS = new Map<string, LocalizationContext>();

export function getLocalizationContext(locale: string) {
    let lc = LOCALIZATION_CONTEXTS.get(locale);

    if (!lc) {
        lc = new LocalizationContextBuilder()
            .withLocale(locale)
            .withDefaultLocale(DEFAULT_LOCALE)
            .build();

        LOCALIZATION_CONTEXTS.set(locale, lc);
    }

    return lc;
}

export function translate(
    bundle: MessageBundle,
    stringId: string,
    args?: Record<string, unknown>
) {
    if (!bundle || !stringId) {
        return undefined;
    }

    if (!args) {
        return bundle.getMessage(stringId);
    }

    return bundle.formatMessage(stringId, args);
}
