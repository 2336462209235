import * as React from "react";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import logo from "../../images/logo.png";

export default () => {
    return (
        <TopNavigation
            identity={{
                href: "#",
                logo: { src: logo, alt: "Amazon Devices" }
            }}
            utilities={[]}
        />
    );
}