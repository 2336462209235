import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {DEFAULT_LOCALE} from "src/constants";

global.config = {
    lng: DEFAULT_LOCALE
}

let isInitialized = false;
let initializationError: string | undefined;

type I18nCallback = (err?: string) => void;
let i18nInitializationCallbacks: I18nCallback[] = [];

i18n.use(LanguageDetector)
    .init(
        {
            detection: {
                order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            } ,
            fallbackLng: DEFAULT_LOCALE
        },
        (err?: string) => {
            initializationError = err;
            isInitialized = true;
            i18nInitializationCallbacks.forEach(callback => {
                callback(initializationError);
            });
            i18nInitializationCallbacks.length = 0;
        }
    ).then(_ => {});

i18n.on('languageChanged', lng => {
    global.config.lng = lng;
});

const i18nWhenReady = (callback: I18nCallback) => {
    if (isInitialized) {
        callback(initializationError);
    } else {
        i18nInitializationCallbacks.push(callback);
    }
};

export { i18nWhenReady };
export default i18n;