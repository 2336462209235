import * as React from "react";
import {Component} from "react";
import {Grid} from "@amzn/awsui-components-react";
import Content, {NotificationPreferenceViewType} from "src/components/notification-preferences/content";
import {PreferenceStatus} from "src/components/notification-preferences";
import {NOTIFICATION_PREFERENCE_ERROR_PATH} from "src/components/App";
import { Navigate } from "react-router-dom";

export const NEW_PREFERENCE_PARAM_KEY = "newPreference";

export default class NotificationPreferencesSaved extends Component<{}, { newPreferenceStatus: PreferenceStatus, error: boolean }> {

    constructor(props: any) {
        super(props);
        this.state = { newPreferenceStatus: PreferenceStatus.ACCEPT, error: false}
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        if (params.has(NEW_PREFERENCE_PARAM_KEY) && params.get(NEW_PREFERENCE_PARAM_KEY) != null){
            const newPreference = params.get(NEW_PREFERENCE_PARAM_KEY);
            this.setState({
                newPreferenceStatus: PreferenceStatus[newPreference as keyof typeof PreferenceStatus]
            })
        }else{
            this.setState({error: true})
        }
    }

    render() {

        return (
            <div className="app-container">
                <Grid
                    disableGutters
                    gridDefinition={[
                        {
                            colspan: {xl: 4, l: 4, m: 6, s: 8, xxs: 12},
                            offset: {xl: 4, l: 4, m: 3, s: 2, xxs: 0}
                        },
                    ]}>
                    <Content viewType={NotificationPreferenceViewType.saved} newPreference={this.state.newPreferenceStatus}/>
                </Grid>
                {this.state.error && <Navigate to={NOTIFICATION_PREFERENCE_ERROR_PATH} replace={true} /> }
            </div>
        );;
    }
}