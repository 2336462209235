import React from 'react';
import TopNavigation from "src/components/navigation/TopNavigation";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotificationPreferencesSaved from "src/components/notification-preferences/saved";
import NotificationPreferencesError from "src/components/notification-preferences/error";
import {MbmProvider} from "@amzn/react-arb-tools";
import arbManifest from '../i18n/translations/arbManifest';
import {TRANSLATION_ROUTE} from "src/constants";
import {getLocalizationContext} from "src/helpers";
import {NotificationPreferences} from "src/components/notification-preferences";
import {i18nWhenReady} from "src/i18n";
import {LocalizationContext} from "@amzn/i18n.localization-context";

export const NOTIFICATION_PREFERENCE_PATH = "notification-preferences/";
export const NOTIFICATION_PREFERENCE_SAVED_PATH = "notification-preferences/saved";
export const NOTIFICATION_PREFERENCE_ERROR_PATH = "notification-preferences/error";

export class App extends React.Component<{}, { mbmOptions: {
        arbManifest: any,
        defaultLocalizationContext: LocalizationContext,
        urlPrefix: string } } > {

    componentDidMount() {
        i18nWhenReady(() => {
            this.setState({ mbmOptions: {
                    arbManifest,
                    defaultLocalizationContext: getLocalizationContext(global.config.lng),
                    urlPrefix: TRANSLATION_ROUTE,
                }})
        })
    }

    render() {
        if (this.state){
            return (
                <MbmProvider urlPrefix={this.state.mbmOptions.urlPrefix}
                             arbManifest={this.state.mbmOptions.arbManifest}
                             defaultLocalizationContext={this.state.mbmOptions.defaultLocalizationContext}>
                    <BrowserRouter>
                        <TopNavigation></TopNavigation>
                        <Routes>
                            <Route
                                path={"/"}
                                element={<NotificationPreferences />}
                            />
                            <Route
                                path={NOTIFICATION_PREFERENCE_PATH}
                                element={<NotificationPreferences />}
                            />
                            <Route
                                path={NOTIFICATION_PREFERENCE_SAVED_PATH}
                                element={<NotificationPreferencesSaved />}
                            />
                            <Route
                                path={NOTIFICATION_PREFERENCE_ERROR_PATH}
                                element={<NotificationPreferencesError />}
                            />
                        </Routes>
                    </BrowserRouter>
                </MbmProvider>
            );
        }else{
            return (<div></div>);
        }


    }
}

export default App;
