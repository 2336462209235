const BETA_STAGE = "beta";
const GAMMA_STAGE = "gamma";
const PROD_STAGE = "prod";

const API_DOMAIN_BETA = "api.devicereleasenotes.ds2.amazon.dev";
const API_DOMAIN_GAMMA = "api.devicereleasenotes.ds2.amazon.dev";
const API_DOMAIN_PROD = "api.devicereleasenotes.amazon";

export function getApiBaseUrl(stage: string): string {
    switch (stage) {
        case BETA_STAGE:
            return `https://${stage}.${API_DOMAIN_BETA}`
        case GAMMA_STAGE:
            return `https://${stage}.${API_DOMAIN_GAMMA}`
        case PROD_STAGE:
            return `https://${API_DOMAIN_PROD}`
        default:
            throw new Error(`Unsupported ${stage} stage`);
    };
}