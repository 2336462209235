import * as React from "react";
import {Box, Button, Container, ContentLayout, RadioGroup, SpaceBetween} from "@amzn/awsui-components-react";
import Api from "src/api/ApiFactory";
import Loading from "src/components/notification-preferences/content/loading";
import { useNavigate, useSearchParams } from "react-router-dom";


import "./styles.scss";

import unsubscribeLogo from '../../../images/notification-preferences/unsubscribe.png';
import savedLogo from '../../../images/notification-preferences/saved.png';
import errorLogo from '../../../images/notification-preferences/error.png';
import {PreferenceStatus} from "src/components/notification-preferences";
import {NOTIFICATION_PREFERENCE_ERROR_PATH, NOTIFICATION_PREFERENCE_SAVED_PATH} from "src/components/App";
import {NEW_PREFERENCE_PARAM_KEY} from "src/components/notification-preferences/saved";
import {useBundle} from "@amzn/react-arb-tools";
import {HOMEPAGE_MESSAGE_BUNDLE_NAME} from "src/constants";
import {translate} from "src/helpers";
import {logger} from "src/logger";


export enum NotificationPreferenceViewType {
    unsubscribeForm = 0,
    saved = 1,
    error = 2
}


function getTitleTextValue(bundle: any, viewType?: NotificationPreferenceViewType, newPreference?: PreferenceStatus) {
    if (viewType == NotificationPreferenceViewType.unsubscribeForm){
        return translate(bundle, "notification_preference_title");
    }else if (viewType == NotificationPreferenceViewType.saved){
        if (newPreference == PreferenceStatus.REJECT){
            return translate(bundle, "notification_preference_title_optout_option");
        }else if (newPreference == PreferenceStatus.ACCEPT){
            return translate(bundle, "notification_preference_title_optin_option");
        }
    }
    return translate(bundle, "notification_preference_title_error");
}

function getSubTitleTextValue(bundle: any, viewType?: NotificationPreferenceViewType, newPreference?: PreferenceStatus) {
    if (viewType == NotificationPreferenceViewType.saved){
        return translate(bundle, "notification_preference_update_wait");
    } else {
        return "";
    }
}

function getImage(viewType?: NotificationPreferenceViewType) {
    if (viewType == NotificationPreferenceViewType.unsubscribeForm){
        return unsubscribeLogo;
    }else if (viewType == NotificationPreferenceViewType.saved){
        return savedLogo;
    }
    return errorLogo;
}

export default (params: { viewType: NotificationPreferenceViewType, newPreference?: PreferenceStatus, token?: string}) => {
    const [value, setValue] = React.useState(PreferenceStatus.ACCEPT);
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
    const savePreference = async (preference: PreferenceStatus) => {
        setIsLoading(true);
        const token = searchParams.get('T') || '';

        try {
            switch (preference) {
                case PreferenceStatus.ACCEPT:
                    await Api().optInAll({
                        optInAllRequestContent: {
                            token: token,
                        }
                    });
                    break;
                case PreferenceStatus.REJECT:
                    await Api().optOutAll({
                        optOutAllRequestContent: {
                            token: token,
                        }
                    });
                    break;
                default:
                    break;
            }
            navigate(`/${NOTIFICATION_PREFERENCE_SAVED_PATH}?${NEW_PREFERENCE_PARAM_KEY}=${preference}`)
        } catch (e) {
            logger.error(e + '').then()
            navigate(`/${NOTIFICATION_PREFERENCE_ERROR_PATH}`)
        }


        setIsLoading(false);
    }

    if (isLoading){
        return (
            <Box padding={{top: 'xxxl'}}>
                <Loading />
                <h3>{translate(bundle, "notification_preference_title_saving")}</h3>
            </Box>
        )
    }else{
        return (
            <Box padding={{top: 'xxxl'}}>
                <img src={getImage(params.viewType)}/>
                <h1>{getTitleTextValue(bundle, params.viewType, params.newPreference) }</h1>
                <h3>{getSubTitleTextValue(bundle, params.viewType, params.newPreference) }</h3>
                {
                    params.viewType == NotificationPreferenceViewType.unsubscribeForm ? (<SpaceBetween size={"m"}>
                        <div className={"spacer"}></div>
                        <RadioGroup
                            id={"preferenceRadioGroup"}
                            onChange={({ detail }) => setValue(PreferenceStatus[detail.value as keyof typeof PreferenceStatus])}
                            value={value}
                            items={[
                                { value: PreferenceStatus.ACCEPT, label: translate(bundle, "notification_preference_optin_option") },
                                { value: PreferenceStatus.REJECT, label: translate(bundle, "notification_preference_optout_option") }
                            ]}
                        />
                        <Button id={"submitBtn"} variant="primary" onClick={() => savePreference(value)}>{translate(bundle, "notification_preference_submit")}</Button>
                    </SpaceBetween>) : (<div></div>)
                }
            </Box>
        )

    }
}