import React from 'react';
import ReactDOM from 'react-dom';
import {Spinner} from "@amzn/awsui-components-react";

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './logger';

import "./index.scss";
import '@amzn/awsui-global-styles/polaris.css';
import "./i18n"

ReactDOM.render(
    <React.StrictMode>
        <AppInitWrapper>
            <React.Suspense fallback={<Spinner size="large" />}>
                <App />
            </React.Suspense>
        </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);
